class RecentStorage {
    storage = null;
    key = 'recent';
    constructor(){
        this.init();
    }
    init = () => {
        try{
            if(this.storage == null && typeof localStorage !== 'undefined')
                this.storage = localStorage;
        } catch(e){
            this.storage = null;
        }
    }
    set = (value) => {
        if(this.storage != null)
            this.storage.setItem(this.key, JSON.stringify(value));
    }
    add = (value) => {
        if(this.storage != null){
            let list = this.get();
            let ex = list.filter(x => x.id == value.id);
            list = list != null ?  list.filter(x => x.id != value.id) : [];
            if(ex.length > 0){
                value.count = ex[0].count + 1;
            } else value.count = 1;
            list.push(value);
            this.set(list);
        }
    }
    get = () => {
        if(this.storage != null){
            let val = this.storage.getItem(this.key);
            return val != null ? JSON.parse(val) : [];
        }
    }
    list = (id) => {
        if(this.storage != null){
            let list = this.get();
            if(id)
            {
                list = list.filter(x => x.id != id);

            }
            list.sort(function(a, b) {
                return b.count - a.count;
            });
            return list.slice(0, 10);
        }
    }
    update = (value) => {
        if(this.storage != null){
            let val = JSON.parse(this.storage.getItem(this.key));
            val.push(value);
            this.set(val);
        }
    }
}

module.exports = RecentStorage;