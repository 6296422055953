import { server, domains, locales } from "../config/index";
import { useState, useEffect } from 'react';
import Meta from "../components/Meta";
import Header from "../components/Header";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import HumoqImage from "../components/HumoqImage";
import HumoqImage2 from "../components/HumoqImage2";
const DeviceDetector = require("node-device-detector");
const DeviceHelper = require("node-device-detector/helper");
const adSlots = [5];
import RecentStorage from "../components/RecentStorage";

const gameIconsGifs = [
  'basket-random',
  'basketball-stars',
  'bitlife-life-simulator',
  'bob-the-robber',
  'boxing-random',
  'fire-and-water-birds',
  'fireboy-and-watergirl-1-forest-temple',
  'fireboy-and-watergirl-2-light-temple',
  'fireboy-and-watergirl-3-ice-temple',
  'fireboy-and-watergirl-5-elements',
  'fireboy-watergirl-6-fairy-tales',
  'hungry-shark-arena',
  'kogama-ghost-house',
  'paperio-2',
  'snow-rider-3d',
  'squid-challenge'
];

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener("resize", handleResize);
     
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export default function Index({
  games,
  categories,
  isMobile,
  deviceOSFamily,
  domainId,
}) {
  const size = useWindowSize();
  const [maxBigImageIndex, setMaxBigImageIndex] = useState(15);
  const router = useRouter();
  const locale = router.defaultLocale;
  const { t } = useTranslation("common");
  const [hoveredGame, setHoveredGame] = useState("");
  const onMouseEnter = (slug) => {
    setHoveredGame(slug);
  }
  const onMouseLeave = (slug) => {
    setHoveredGame(slug);
  }
  const [recent, setRecent] = useState([]);
  const rs = new RecentStorage();
  const isHovered = (slug) => {
    if(hoveredGame != "" && hoveredGame == slug && gameIconsGifs.indexOf(slug) != -1) return true;
    else return false
  }
  useEffect(() => {
    setRecent(rs.list());
  }, [])
  
  useEffect(() => {
    setMaxBigImageIndex((size.width<768 ? 6 : size.width < 1080 ? 11 : 15));
  }, [size]);
  
  useEffect(() => {
    for (let a = 0; a < adSlots.length; a++) {
      var s = document.createElement("script");
      s.setAttribute("src", "https://tag.winister.app/v1/snack");
      //s.setAttribute('src', 'http://localhost:3001/dist/empty/iife/index.js');
      //s.setAttribute('src', 'https://pm.winister.app/@byprofit/pro-snack/0.1.43/dist/default/iife/index.js');
      s.setAttribute("async", true);
      s.setAttribute("data-fit", "v");
      s.setAttribute("data-content-type", "page");
      s.setAttribute("data-tag-id", "0");
      s.setAttribute("callback-fn", "() => {console.log('callback invoked');}");
      //document.getElementById('adContainer' + adSlots[a]).appendChild( s );
    }
  }, []);

  const gameTmpList = [...games];
  const topGames = gameTmpList.splice(0, 4);
  const topGamesIds = topGames.map(g => g.id);
  let recentTmp = recent.filter(r => topGamesIds.indexOf(r.id) == -1);
  const recentIds = recentTmp.map(r => r.id);
  let gameList = topGames.concat(recentTmp, gameTmpList.filter(x => recentIds.indexOf(x.id)==-1));

  return (
    <>
      <Meta
        title={t("HomepageTitle")}
        description={t("HomepageDescription")}
        image={`https://${t("DOMAIN")}/facebook.jpg`}
        url={`/`}
      />
      <Header cat={categories} />
      <div className="humoqRow">
        <div className="containerNew">
          <div className="humoqWrapper">
            <div className="mobileHomeMenu">
              <Menu catUrl={categories} />
            </div>

            {gameList?.map((game, i) => {
              let res;
                res = (
                  <a
                    alt={game.title}
                    key={i}
                    href={`/${game.slug}`}
                    className={"hqCol col-" + i + " -" + maxBigImageIndex}
                    onMouseEnter={() => {onMouseEnter(game.slug)}}
                    onMouseLeave={() => {onMouseLeave(game.slug)}}

                  >
                    <HumoqImage
                      alt={game?.title}
                      width={
                        i <= maxBigImageIndex
                          ? 512
                          : 140
                      }
                      type="homepage"
                      isHovered={isHovered(game.slug) && i<maxBigImageIndex}
                      maxBigImageIndex={maxBigImageIndex}
                      height={
                        i <= maxBigImageIndex
                          ? 512
                          : 140
                      }
                      i={i}
                      src={game.slug}
                    />
                    <div className="humoqText">{game.title}</div>
                  </a>
                );
              return res;
            })}
          </div>
          <div className="catScope">
            {categories?.map((cat, i) => (
              <a
                alt={cat?.name + " " + t("MOST_POPULAR")}
                key={i}
                href={`/c/${cat.slug}`}
                className="catBox"
              >
                <div className="catImg">
                  <HumoqImage2
                    alt={`${cat.name}`}
                    src={`/category-icons/${locale}/${cat.slug}.png`}
                    height={30}
                    width={30}
                  />
                </div>
                <div className="catText">{cat.name}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="humoqRow gameDetails">
        <div className="containerNew">
          <div className="contentScope">
            <h1
              dangerouslySetInnerHTML={{ __html: t("homepageAboutUsTitle") }}
            />
            <div dangerouslySetInnerHTML={{ __html: t("homepageAboutUs") }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export const getServerSideProps = async (context) => {
  const detector = new DeviceDetector();
  const resultOs = detector.parseOs(context.req.headers["user-agent"]);
  const deviceOSFamily = resultOs.family;
  const agentResult = detector.detect(context.req.headers["user-agent"]);
  const isMobile = DeviceHelper.isMobile(agentResult);
  const domainId = locales[context.defaultLocale];
  const url = `${server}/api/v1/games/homepage?domain_id=${domainId}`;
  const category_url = `${server}/api/v1/categories?domain_id=${domainId}`;
  const res = await fetch(url);
  const res_category = await fetch(category_url);
  let resultJSON;
  let resultCatJSON;
  try {
    resultJSON = await res.json();
  } catch (e) {
    console.error("res json error", url, res);
  }
  try {
    resultCatJSON = await res_category.json();
  } catch (e) {
    console.error("res_category json error", category_url, res_category);
  }
  let games = resultJSON.data || [];
  const categories = resultCatJSON.data || [];

  const permanentAdDetail = {
    // 1: {
    //     type: 'permanantAd',
    //     key : 'hidden-master-puzzle',
    //     android: 'https://play.google.com/store/apps/details?id=com.gitberry.hiddenmaster&hl=tr&gl=US',
    //     ios: 'https://apps.apple.com/us/app/hidden-master-puzzle/id1532648388',
    //     image: 'hidden-master-puzzle.webp'
    // },
    1: {
      type: "permanantAd",
      key: "match-3d-master",
      android: null, //'https://play.google.com/store/apps/details?id=com.gitberry.hiddenmaster&hl=tr&gl=US',
      ios: "https://apps.apple.com/us/app/match-3d-master/id1549465450",
      image: "match-3d-master.webp",
    },
    9: {
      type: "permanantAd",
      key: "kartoon",
      android:
        "https://play.google.com/store/apps/details?id=com.sm.kartoon&hl=tr&gl=US",
      ios: "https://apps.apple.com/us/app/kartoon/id1621501888",
      image: "kartoon.webp",
    },
    10: {
      type: "permanantAd",
      key: "jigsaw-master-puzzle",
      android:
        "https://play.google.com/store/apps/details?id=com.gitberry.jigsawmaster&hl=tr&gl=US",
      ios: "https://apps.apple.com/us/app/jigsaw-master-puzzle/id1537170023",
      image: "jigsaw-master-puzzle.webp",
    },
  };

  //[...games.slice(0,1), permanentAdDetail[1], permanentAdDetail[2], ...games.slice(1,3), {type:"adContainer"}, ...games.slice(3,8), permanentAdDetail[9], permanentAdDetail[10], ...games.slice(8)];

  // games.splice(1, 0, permanentAdDetail[1]);

  // for(let a=0; a<adSlots.length;a++)
  //     games.splice(adSlots[a], 0, {type:"adContainer"});

  // games.splice(8, 0, permanentAdDetail[9]);

  return {
    props: {
      games: games.slice(0,130),
      categories,
      isMobile,
      deviceOSFamily,
      domainId,
      ...(await serverSideTranslations(context.defaultLocale, ["common"])),
    },
  };
};
