import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import Script from 'next/script'

function Meta({title, description, url, image}) {  
    // Languages
    const { t } = useTranslation('common');
    const DOMAIN = t('DOMAIN');
    const GA = t('GA');
    const GA2 = t('GA2');
    let videoVastURL = t('videoVastURL');
    // videoVastURL = 'http://localhost:8082/v1/O4VK?referrer=https%3A%2F%2Fhumoq.com';
    const GA_URL = `https://www.googletagmanager.com/gtag/js?id=${GA}`;
    const organizationRickData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": `https://${t('DOMAIN')}`,
        "logo": `https://${t('DOMAIN')}/logo.png`
    }
    return (
        <>
        <Head>
            <title>{title}</title>
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0' />
            <meta name='description' content={description} />
            <meta charSet='utf-8' />

            <meta property="og:url" content={`https://${DOMAIN}${url}`} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`${title}`} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            
            <meta name='application-name' content='Humoq' />
            <meta name='apple-mobile-web-app-capable' content='yes' />
            <meta name='apple-mobile-web-app-status-bar-style' content='default' />
            <meta name='apple-mobile-web-app-title' content='Humoq' />
            <meta name='format-detection' content='telephone=no' />
            <meta name='mobile-web-app-capable' content='yes' />

            <link rel='apple-touch-icon' href='/icons/apple-touch-icon.png' />
            <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
            <link rel='apple-touch-icon' sizes='152x152' href='/icons/favicon-152x152.png' />
            <link rel='apple-touch-icon' sizes='167x167' href='/icons/favicon-167x167.png' />
            
            <link rel="manifest" href="/manifest.json" />
            <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
            <link rel='icon' href='/favicon.ico' />
            <meta name="theme-color" content="#005fd7" />
            <meta name="google-site-verification" content="4zFZxhkX02v30Zq9u7CuXnrMgEpjkH0vFOYkpnAv75U" />
            <meta name="yandex-verification" content="0e6d0a8bb6555cf6" />
            <script defer src={GA_URL}></script>
            <script defer dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
            
                gtag('config', '${GA}');
                gtag('config', '${GA2}');
                dataLayer.push(["_set", "title", document.title]);
                dataLayer.push(["_trackPageview"]);`
            }}>
            </script>
            <script strategy="beforeInteractive" dangerouslySetInnerHTML={{
                __html: `var gameVideoVastURL = '${videoVastURL}';`
            }}>
            </script>
            <script strategy="lazyOnload" src="/permission.js"></script>
            <script src="https://tag.gitberry.com/v1/thunder" async></script>
            {/** <script async src="/ana.js"></script>*/}
            <script async src="/ads.js"></script>
            <script async src="/help.js"></script>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationRickData) }} />
        </Head>
        </>
    )

}

export default Meta;